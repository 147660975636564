* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-image: url('./media/Bamboo.jpg');
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /*color: #61dafb;*/
  color: black;
  font-family: 'Archivo', sans-serif;
  font-size: 20px;
}

h1, h2, h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  color: red;
  text-shadow: 1px 1px black;
}

label {
  margin-bottom: 0;
  font-weight: bold;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
}

a {
  overflow-wrap: break-word;
}

.robotoCondensed {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}

.websiteLogo {
  height: auto;
  width: 60%;
}

.link {
  color: red;
  font-size: 34px;
  text-shadow: 1px 1px black;
}

.profileImage {
  height: auto;
  width: 70%;
  border: 5px solid  rgba(15,225,245,1);
  border-radius: 50%;
}

.projectImage {
  padding: 15px;
}

.img-thumbnail {
  box-shadow: 12px 12px 12px rgb(0 0 0 / 50%);
}

.whiteBackground {
  background-color: rgba(255,255,255, 0.9);
  border-radius: 25px;
  padding: 5px;
  margin: 5px;
}

.requiredField {
  color: red;
}

@media only screen and (min-width: 768px) {
  textarea {
    width: 350px;
  }
  
  #paddingFix {
    padding-top: 8%;
  }

  #resumeLink {
    height: 100%;
    width: auto;
  }
}

@media only screen and (max-width: 990px) {
  .websiteLogo {
    height: auto;
    width: 25%;
  }
}

@media only screen and (max-width: 500px) {
  .websiteLogo {
    height: auto;
    width: 40%;
  }
}

@media only screen and (max-width: 360px) {
  audio {
    width: 100%;
  }
}
